<template>
  <div v-if="count  !== null">
    <survey-resume
      :count="count"
      :totalResults="totalResults"
      :results="results"
    />
    <list 
      :results="results"
      :onVisitorCenterFiltered="handleSelectVisitorCenter"
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
      :onGetData="handleGetData"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import SurveyResume from './SurveyResume'
import List from './List'
export default {
  components: {
    SurveyResume,
    List,
  },
  data: () => ({
    count: null,
    loading: false,
    totalResults: null,
    results: null,
    selectedVisitorCenter: null,

    startIndex: 0,
    itemsPerPage: 10,
    searchValue: '',
  }),
  computed: {
    ...mapState('app',['locale']),
    workspaceID () {
      return null
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleSelectVisitorCenter (v) {
      this.selectedVisitorCenter = v
      this.handleGetData()
    },
    handleGetData (startIndex) {
      if (startIndex >=0) this.startIndex = startIndex
      this.loading = true
      this.ofs = []
      api.getAll ('turismo', `v1/private/surveys-results/${this.selectedVisitorCenter ? this.selectedVisitorCenter : ''}`, this.startIndex, this.itemsPerPage, this.searchValue)
        .then(response => {
          if (!this.selectedVisitorCenter) this.totalResults = response.totals
          this.count = response.count
          this.results = response.data.map(item => {
            item.Result = JSON.parse(item.Result)
            return item
          })
          this.loading = false
        })

    },
  }
}
</script>

